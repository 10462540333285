import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectTracksidePageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { GatsbyImageGrid, TGatsbyImageGridItem } from '../../components/GatsbyImgGrid';
import { Link } from '../../components/Link';

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
}));

export type TProjectTracksideP = {
    data: GQLProjectTracksidePageQuery;
};

function ProjectTrackside(props: TReactFCP<TProjectTracksideP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const {
        sitePage, projectsYaml, eventDetailOverview, eventDetailOverviewMobile, eventDetailSchedules,
        eventDetailSchedulesMobile, events, eventsMobile1, eventsMobile2, tracks, tracksMobile,
        organizationListingMobile
    }: GQLProjectTracksidePageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectTracksidePageQuery['projectsYaml']> = projectsYaml!;
    const eventDetailOverviewImages: TGatsbyImageGridItem[] = [
        {
            originalSrc: eventDetailOverviewMobile!.childImageSharp!.original!.src!,
            image: eventDetailOverviewMobile!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: eventDetailOverviewMobile!.childImageSharp!.fluid!.aspectRatio!,
        },
        {
            originalSrc: eventDetailOverview!.childImageSharp!.original!.src!,
            image: eventDetailOverview!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: eventDetailOverview!.childImageSharp!.fluid!.aspectRatio!,
        },
    ];
    const eventDetailScheduleImages: TGatsbyImageGridItem[] = [
        {
            originalSrc: eventDetailSchedulesMobile!.childImageSharp!.original!.src!,
            image: eventDetailSchedulesMobile!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: eventDetailSchedulesMobile!.childImageSharp!.fluid!.aspectRatio!,
        },
        {
            originalSrc: eventDetailSchedules!.childImageSharp!.original!.src!,
            image: eventDetailSchedules!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: eventDetailSchedules!.childImageSharp!.fluid!.aspectRatio!,
        },
    ];
    const eventsMobileImages: TGatsbyImageGridItem[] = [
        {
            originalSrc: eventsMobile1!.childImageSharp!.original!.src!,
            image: eventsMobile1!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: eventsMobile1!.childImageSharp!.fluid!.aspectRatio!,
        },
        {
            originalSrc: eventsMobile2!.childImageSharp!.original!.src!,
            image: eventsMobile2!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: eventsMobile2!.childImageSharp!.fluid!.aspectRatio!,
        },
        {
            originalSrc: events!.childImageSharp!.original!.src!,
            image: events!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: events!.childImageSharp!.fluid!.aspectRatio!,
        },
    ];
    const tracksImages: TGatsbyImageGridItem[] = [
        {
            originalSrc: tracksMobile!.childImageSharp!.original!.src!,
            image: tracksMobile!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: tracksMobile!.childImageSharp!.fluid!.aspectRatio!,
        },
        {
            originalSrc: tracks!.childImageSharp!.original!.src!,
            image: tracks!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: tracks!.childImageSharp!.fluid!.aspectRatio!,
        },
        {
            originalSrc: organizationListingMobile!.childImageSharp!.original!.src!,
            image: organizationListingMobile!.childImageSharp!.fluid! as FluidObject,
            aspectRatio: organizationListingMobile!.childImageSharp!.fluid!.aspectRatio!,
        },
    ];
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                Trackside is my first foray into progressive, offline-enabled web applications and is the first app
                for <Link variant='external' to='https://paddock.tech/'>Paddock Tech</Link>, a motorsports technology
                company that{` `}
                <Link variant='internal' to='/blog/building-paddock-tech-part-1/'>I'm building</Link>
                . I've built the entire backend and frontend so only the main features of the app are highlighted
                for brevity. More detailed and technical deep dives are available in{` `}
                <Link variant='internal' to='/blog/topic/paddock-tech/'>my blog</Link>.
            </Typography>
            <Typography paragraph>
                Born out of a need for accurate and to-the-minute information about the state of a{` `}
                <Link variant='external' to='https://en.wikipedia.org/wiki/Track_day'>track event</Link>{` `}
                in real-time, the goal for this app is to centralize critical event information to make it easily
                discoverable and consumable at a glance. Since the only device most people have at these events is
                their mobile phone, the app is heavily optimized for mobile.
            </Typography>
            <GatsbyImageGrid rows={[eventDetailOverviewImages]} spacing={4} paragraph />
            <Typography paragraph>
                One of the most frequent things that participants do is check the schedule to determine where they need
                to be and when. To make this information skimmable, summaries and <i>time-until</i> countdowns for the
                current and upcoming activities are presented within first screen. With a single tap, all detailed
                information about each activity is viewable.
            </Typography>
            <Typography paragraph>
                Another common knowledge gap is where the drivers can pass. We color the track map in accordance to
                permitted passing zones depending on skill level.
            </Typography>
            <GatsbyImageGrid rows={[eventDetailScheduleImages]} spacing={4} paragraph />
            <Typography paragraph>
                Traditional schedules can to be hard to read and follow. Each event has a graphical timeline
                representation of the schedule that follows the current local time. Each group has a color which
                matches the color of the arm band handed out to participants at the track for easy identification.
                Event staff and driving instructors benefit from being able to view the exact state of the event across
                all skill level groups.
            </Typography>
            <GatsbyImageGrid rows={[eventsMobileImages, tracksImages]} spacing={4} paragraph />
            <Typography paragraph>
                In order for participants to find their event, Trackside includes an in-depth event discovery engine.
                Filter by track, organization, event format, and more. Users can also discover new tracks and events
                that they may want to attend later in the season.
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectTracksideP>): TReactFCR => (
    <Page component={ProjectTrackside} cprops={props} />
);

export const query = graphql`
    query ProjectTracksidePage {
        sitePage(internalComponentName: {eq: "ComponentProjectsTrackside"}) {
            path
        }
        projectsYaml(pid: {eq: "trackside"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
        eventDetailOverviewMobile: file(relativePath: {eq: "images/projects/trackside/event-detail-overview-mobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        eventDetailOverview: file(relativePath: {eq: "images/projects/trackside/event-detail-overview-nonmobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        eventDetailSchedulesMobile: file(relativePath: {eq: "images/projects/trackside/event-detail-schedules-mobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        eventDetailSchedules: file(relativePath: {eq: "images/projects/trackside/event-detail-schedules-nonmobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        organizationListingMobile: file(relativePath: {eq: "images/projects/trackside/organization-listing-mobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        eventsMobile1: file(relativePath: {eq: "images/projects/trackside/events-mobile1.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        eventsMobile2: file(relativePath: {eq: "images/projects/trackside/events-mobile2.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        events: file(relativePath: {eq: "images/projects/trackside/events-grid.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        tracksMobile: file(relativePath: {eq: "images/projects/trackside/tracks-mobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
        tracks: file(relativePath: {eq: "images/projects/trackside/tracks-nonmobile.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                }
                original {
                    src
                }
            }
        }
    }
`;
